<template>
  <v-container>
    <v-card :color="$commonProcessor.styleMapping(card.activityType).backgroundColor" v-if="loaded" class="py-12">
      <div align="center" class="pb-2">
        <v-icon size="100" color="white" class="pt-4">{{ getIcon() }}</v-icon>

        <h1 class="white--text">
          {{ card.name }}
        </h1>

        <h3 class="white--text font-weight-light pt-2" v-if="card.description">
          {{ $languageProcessor.translate(card.description) }}
        </h3>

        <h2 class="white--text font-weight-bold pt-2" v-if="card.points">
          {{ `${card.points} ${$t('points')}` }}
        </h2>
      </div>
  
      <div class="py-2" v-if="card && card.config">
        <youtube class="px-12" v-if="card.config.youtubeVideoId"
          :resize="true"
          :fitParent="true"
          :video-id="card.config.youtubeVideoId"
          ref="youtube"
        />

        <div v-for="(step, step_i) in card.config.steps" :key="`step_${step_i}`"
          class="px-12 py-2"
        >
          <v-card class="pa-4">
            <v-row style="flex: 1; alignItems: center; alignContent: center;">
              <div class="px-4">
                <v-chip text-color="white" :color="$commonProcessor.colorLuminance($commonProcessor.styleMapping(card.activityType).backgroundColor, -0.15)">
                {{ step_i + 1 }}
                </v-chip>
              </div>

              <div style="flex: 1" v-html="$languageProcessor.translate(step)" />
            </v-row>
          </v-card>
        </div>
      </div>

      <div align="center" class="py-4" color="pink" v-if="card.config && card.config.fileExtension">
        <input type="file" ref="file" style="display: none" 
          @change="e => onFileChange(e, card)"
          :accept="card.config.fileExtension" 
        >
        <v-btn rounded color="white" large width="200" 
          @click="$refs.file.click()"
          :class="{'disable-events': disableButton }"
          :loading="disableButton"
        >
          {{ $t('upload_file') }}
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { DataService } from '../../services/DataService'

const dataService = new DataService()

export default {
  data() {
    return {
      disableButton: false,
      loaded: false,
      card: {},
    }
  },
  created() {
    this.$cookies.set('platform', 'web')
  },
  mounted() {
    if (this.$route.params.card) {
      this.card = this.$route.params.card
      this.loaded = true
    } else {
      this.$router.push({ name: 'Root' })
    }
  },
  methods: {
    getIcon() {
      return (this.card.config && this.card.config.frontendIcon) ? this.card.config.frontendIcon : null
    },
    onFileChange(e, card) {
      this.disableButton = true

      let files = e.target.files || e.dataTransfer.files;
      let formData = new FormData();
      let maxFileSize = (card.config && card.config.maxFileSize) ? card.config.maxFileSize : 0
      let fileSize = files[0].size/1024/1024

      if (fileSize <= card.config.maxFileSize) {
        formData.append('file', files[0]);
        formData.append('userId', this.$store.state.userProfile._id)
        formData.append('userProfilingId', card._id)
        
        dataService.upload(this.$store.state.token, formData).then(() => {
          this.disableButton = false
          this.$refs.file.value = ''
          
          this.$showSuccessMessage({ message: this.$t("uploaded_and_task_completed") })
          this.$router.push({ name: 'Root' })
        }).catch(() => {
          this.disableButton = false
          this.$refs.file.value = ''
        })
      } else {
        this.$showSuccessMessage({ message: this.$t("file_exceeded_limit", [maxFileSize, parseFloat(fileSize).toFixed(2)]) })
        this.$refs.file.value = ''
        this.disableButton = false
      }
    },
  }
}
</script>

<style>
.disable-events {
  pointer-events: none
}
</style>